<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/medicine_case_bg.jpg" alt="" />
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <Title titleZh="解决方案" marginTopZh="50px"></Title>
        <img class="w90f" src="../../assets/images/medicine_case_block0.png" alt="" />
        <Title titleZh="方案介绍"></Title>
        <img class="w100f" src="../../assets/images/medicine_case_block1.png" alt="" />
        <Title titleZh="以供应链数据为基础精准营销"></Title>
        <div class="gyl-box">
          <div class="gyl-list">
              <img src="../../assets/images/medicine_case_a1.png" alt="">
              <div class="gyl-txt-box">
                  <div class="gyl-txt-title">数字化供应链</div>
                  <p>依托于6年间积累的天网地网，实现工业各环节的订单、库存、运力的协同。实现多级库存优化并及时响应终端，智能调度优化运输成本。</p>
              </div>
          </div>
          <div class="gyl-list">
              <img src="../../assets/images/medicine_case_a2.png" alt="">
              <div class="gyl-txt-box">
                  <div class="gyl-txt-title">数字化营销</div>
                  <p>基于工业委托的服务，定制合规营销解决方案，包含终端拜访、产品信息收集、会议直播，市场调研等服务，全流程真实发生，真实内容，真实支付。</p>
              </div>
          </div>
          <div class="gyl-list">
              <img src="../../assets/images/medicine_case_a3.png" alt="">
              <div class="gyl-txt-box">
                  <div class="gyl-txt-title">真实合规行为数据</div>
                  <p>从供应链，渠道管控，人员管理，营销多维度出发，打造线上全链条的数字化，合规行为数据完整留存，助力工业业务健康发展。</p>
              </div>
          </div>
          <div class="gyl-list">
              <img src="../../assets/images/medicine_case_a4.png" alt="">
              <div class="gyl-txt-box">
                  <div class="gyl-txt-title">财税咨询</div>
                  <p>从多维度支持企业税收模块化、属性化和灵活化，通过企业税收的全国布局优化以及与区域产业化契合优化助力企业获得更大税收收益。</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/medicine/mobile/medicine_fbt_case.vue'//移动端页面
export default {
  components: {
    Title,
    mobilePage
  },
  data() {
    return {
     
    };
  },
  created() {},
  methods: {
    tabClick(type) {
      this.tabIndex = type;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 59px;
          color: #4fa1a4;
        }
        .font-txt {
          font-size: 24px;
          color: #3d3d3d;
          .font-txt-tag {
            margin: 0 20px;
          }
        }
        .font-intro {
          font-size: 18px;
          color: #3d3d3d;
          text-align: left;
          line-height: 30px;
          margin-top: 70px;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .gyl-box{
        display: flex;
        margin-top: 20px;
        margin-bottom: 180px;
        .gyl-list{
            position: relative;
            width: 25%;
            color: #fff;
            font-size: 16px;
            .gyl-txt-box{
                position: absolute;
                top: 200px;
                padding: 0 40px;
                text-align: center;
                .gyl-txt-title{
                    font-size: 22px;
                    margin-bottom: 23px;
                }
                p{
                    line-height: 28px;
                }
            }
        }
    }
  }

}
</style>
