import { render, staticRenderFns } from "./mobile_column_box.vue?vue&type=template&id=6e3b17ee&scoped=true"
import script from "./mobile_column_box.vue?vue&type=script&lang=js"
export * from "./mobile_column_box.vue?vue&type=script&lang=js"
import style0 from "./mobile_column_box.vue?vue&type=style&index=0&id=6e3b17ee&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3b17ee",
  null
  
)

export default component.exports