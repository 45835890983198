<template>
  <div class="mobile-column-box">
    <div class="mobile-column-list" v-for="(item, index) in boxList" :key="index">
      <img :src="item.url" alt="" />
      <div class="mobile-column-title" :style="{fontSize:fontSizeTitle}" v-show="item.title">{{ item.title }}</div>
      <p v-html="item.txt"></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    boxList: {
      type: Array,
      default: [],
    },
    fontSizeTitle:{
      type:String,
      default:''
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-column-box {
  padding: 0 0.4rem;
  color: #3d3d3d;
  box-sizing: border-box;
  .mobile-column-list {
    text-align: left;
    img {
      width: 100%;
      margin-bottom: .54rem;
    }
    .mobile-column-title {
      font-size: 0.4rem;
      font-weight: 500;
      padding: 0 0 0.34rem 0;
    }
    p {
      font-size: 0.28rem;
      line-height: 0.5rem;
      margin-bottom: 1.04rem;
    }
  }
}
</style>
