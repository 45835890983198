<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_medicine_case_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <div class="mobile-content">
      <mobileTitle titleZh="解决方案" marginTopZh=".64rem" marginBottomZh=".5rem"></mobileTitle>
      <mobileImg :url="jjfaImg" boxHeight="8.42rem" backColor="#fafafa" boxPadding=".3rem" border=".04rem solid #EBEBEB"></mobileImg>
      <mobileTitle titleZh="方案预测" marginTopZh=".64rem" marginBottomZh=".5rem"></mobileTitle>
      <mobileImg :url="faycImg" boxHeight="8.42rem" backColor="#fafafa" boxPadding=".3rem" border=".04rem solid #EBEBEB"></mobileImg>
      <mobileTitle titleZh="以供应链数据为基础精准营销" marginTopZh=".64rem" marginBottomZh=".5rem"></mobileTitle>
      <img class="img-bottom" src="../../../assets/mobile_images/mo_medicine_case_a1.png" alt="" />
      <img class="img-bottom" src="../../../assets/mobile_images/mo_medicine_case_a2.png" alt="" />
      <img class="img-bottom" src="../../../assets/mobile_images/mo_medicine_case_a3.png" alt="" />
      <img class="img-bottom" src="../../../assets/mobile_images/mo_medicine_case_a4.png" alt="" />
    </div>
  </div>
</template>
<script>
import mobileColumnBox from '@/components/box/mobile_column_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileColumnBox,
    mobileImg
  },
  data() {
    return {
      jjfaImg:require('../../../assets/mobile_images/mo_medicine_case_jjfa.png'),
      faycImg:require('../../../assets/mobile_images/mo_medicine_case_fayc.png')
    };
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-content {
    margin: 0 0.4rem;
    box-sizing: border-box;
    .img-bottom{
      width: 100%;
      margin-bottom: .32rem;
    }
  }
}
</style>
